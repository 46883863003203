import { createSelector, createSlice } from '@reduxjs/toolkit';

const initialState = {
  cmsVersion: 'default',
  isBookingEngine: false,
  brandLoaded: false,
  cssLoaded: false,
  layoutLoaded: false,
  searchFiltersChanged: false,
  mobileToggleSearchMapView: false,
  listingsDetailsChanged: false,
  showDetailsThumbnailGrid: false,
  openContactForm: false,
  openMobileBookingDetails: false,
  checkoutStateChanged: false,
  checkoutActiveStep: 'stepOne',
  verificationStateChanged: false,
  paymentStateChanged: false,
  contractStateChanged: false,
  searchInProgress: true,
  openChildUnitModal: false,
  allowSearchSpinner: false
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    updateUiSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetUiSlice: state => {
      return { ...state, ...initialState };
    }
  }
});

export const { updateUiSlice, resetUiSlice } = uiSlice.actions;

export default uiSlice.reducer;

export const selectUiState = createSelector(
  state => state.ui,
  ui => ui
);
