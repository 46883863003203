import React from 'react';

const PageNotFound404 = () => {
  return (
    <div className={'notFound__container'}>
      <div className={'notFound__background'}>404</div>
      <div className={'notFound__headline'}>
        We're sorry, that page does not exist.
      </div>
      <div>
        If you were search for a listing, it may not be available anymore,
        otherwise, please check that the URL you were trying to visit is spelled
        correctly.
      </div>
    </div>
  );
};

export default PageNotFound404;
