import React from 'react';
import { FlexBox, TextBody } from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../../shared/helpers';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';

const ListingsSearchItemPrice = ({
  amount,
  beforePromoAmount,
  currency,
  isMultiUnit,
  unitCount,
  translate,
  renderAsText
}) => {
  const { datesSet } = useSelector(selectListingsSearchState);

  if (renderAsText) {
    return isMultiUnit
      ? translate(`cx.search.num_units`, {
          num: unitCount,
          s: unitCount === 1 ? '' : 's'
        })
      : formatCurrency(amount, currency, null, true);
  }

  return isMultiUnit ? (
    <FlexBox gap="s">
      <TextBody textColor="dark-gray" weight="semibold">
        {translate(`cx.search.num_units`, {
          num: unitCount,
          s: unitCount === 1 ? '' : 's'
        })}
      </TextBody>
    </FlexBox>
  ) : (
    <FlexBox justifyContent="space-between">
      <FlexBox gap="xxs">
        {!datesSet && (
          <TextBody textColor="dark-gray" weight="semibold">
            From:
          </TextBody>
        )}
        <TextBody textColor="dark-gray" weight="semibold">
          {`${formatCurrency(amount, currency, null, true)}`}
        </TextBody>
        {beforePromoAmount && (
          <TextBody
            textColor="disabled"
            weight="semibold"
            className="strike-through-text"
          >
            {formatCurrency(beforePromoAmount, currency, null, true)}
          </TextBody>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default ListingsSearchItemPrice;
