import React, { useState } from 'react';
import { FormField } from '@directsoftware/ui-kit-web-admin';
import { times } from 'lodash';
import { useSelector } from 'react-redux';
import { selectListingsSearchState } from '../../../redux/slices/listingsSearch';

const ListingsSearchVehicleType = ({ updateSearchState }) => {
  const searchState = useSelector(selectListingsSearchState);
  const options = [
    { label: 'Class A Motorhome', value: '1' },
    { label: 'Class B Camping Van', value: '2' },
    { label: 'Class C Motorhome', value: '3' },
    { label: 'Fifth Wheel', value: '4' },
    { label: 'Pop Up Camper', value: '5' },
    { label: 'Toy Hauler', value: '6' },
    { label: 'Travel Trailer', value: '7' },
    { label: 'Park Model', value: '8' }
  ];

  return (
    <FormField
      labelText="Vehicle Type"
      inputType="select"
      inputProps={{
        options,
        onChange: option => {
          updateSearchState({ vehicle_type: option.value }, true);
        },
        inputWidth: 's',
        value: options.filter(opt => opt.value === searchState.vehicle_type)
      }}
    />
  );
};

export default ListingsSearchVehicleType;
